import * as bootstrap from "bootstrap";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import { CountUp } from "countup.js";
//import '../src/js/ajax';
const throttle = require("lodash/throttle");
const delay = require("lodash/delay");

const menuprimaryEl = document.getElementById("menu-primary");
const menuprimary1El = document.getElementById("menu-primary-1");

const navswiper = new Swiper(".navswiper-container", {
  speed: 400,
  observer: true,
  slidesPerView: 1,
  spaceBetween: 60,
  allowTouchMove: false,
  modules: [Navigation, Pagination, Autoplay],
  /*
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },*/
});

let sw = document.querySelector("#swiper-nav .swiper-wrapper");

if (menuprimaryEl || menuprimary1El) {
  menuprimary1El.addEventListener("shown.bs.collapse", (event) => {
    //console.log("working collapsed", event, this);
    event.target.parentNode.classList.add("expanded");
  });

  menuprimary1El.addEventListener("hidden.bs.collapse", (event) => {
    //console.log("working collapsed", event, this);
    event.target.parentNode.classList.remove("expanded");
  });

  if (
    menuprimary1El.querySelector(".active") &&
    menuprimary1El.querySelector(".active").querySelector(".item")
  )
    menuprimary1El
      .querySelector(".active")
      .querySelector(".item")
      .classList.add("show");

  if (
    menuprimary1El.querySelector(".active.has-children") &&
    menuprimary1El.querySelector(".active.has-children").querySelector(".item")
  )
    menuprimary1El
      .querySelector(".active.has-children")
      .querySelector(".accordion-header")
      .classList.add("expanded");

  if (
    menuprimary1El.querySelector(".active.has-children") &&
    menuprimary1El
      .querySelector(".active.has-children")
      .querySelector(".chevron")
  )
    menuprimary1El
      .querySelector(".active.has-children")
      .querySelector(".chevron")
      .classList.remove("collapsed");

  let list = [...menuprimaryEl.querySelectorAll(".menuitem")];
  let active = document.querySelector(".menuitem.active");
  let activeid = list.indexOf(active);

  if (activeid > 0) navswiper.slideTo(activeid);
  else document.querySelector(".offcanvas-body").classList.add("root");

  const menuprimaryEl_tags = menuprimaryEl.querySelectorAll("a");
  let tag_array = [...menuprimaryEl_tags];
  [].forEach.call(tag_array, (menulink, index, arr) => {
    for (const [i, slide] of navswiper.slides.entries()) {
      let str = slide.id;
      if (index == 0) {
        menulink.parentNode.parentNode.addEventListener("click", (event) => {
          if (navswiper.realIndex !== 0) event.preventDefault();
          document.querySelector(".offcanvas-body").classList.add("root");
          let menuitems =
            event.currentTarget.parentNode.parentNode.querySelectorAll(
              ".menuitem",
            );
          menuitems.forEach((li) => li.classList.remove("active"));
          navswiper.slideTo(0);
        });
      } else if (str.substr(6) == menulink.id) {
        menulink.parentNode.parentNode.addEventListener("click", (event) => {
          event.preventDefault();
          document.querySelector(".offcanvas-body").classList.remove("root");
          let menuitems =
            event.currentTarget.parentNode.parentNode.querySelectorAll(
              ".menuitem",
            );
          menuitems.forEach((li) => li.classList.remove("active"));
          event.currentTarget.parentNode.classList.add("active");
          navswiper.slideTo(i);
        });
      }
    }
  });
}

let lastScrollTop = 0;
let el = document.querySelector(".scroll-header");

let progress_el = document.querySelector(".progress-bar");
let progress = document.querySelector(".progress");

let cta = document.querySelector(".scroll_cta");
let modal_cta = document.querySelector(".modal_cta");
let modal_game = document.querySelector(".modal_game");

if (modal_cta) {
  let ctamodal = new bootstrap.Modal(modal_cta, {
    keyboard: false,
  });

  let mydelay = function () {
    ctamodal.show();
  };

  if (modal_cta) {
    let ctaTime = parseInt(modal_cta.getAttribute("data-time")) || null;
    delay(mydelay, ctaTime * 1000);
  }
}

if (modal_game) {
  let gamemodal = new bootstrap.Modal(modal_game, {
    keyboard: false,
  });

  let delay = function () {
    gamemodal.show();
  };

  delay(delay, 1000);
}

let updatePosition = function (e) {
  e.preventDefault();

  let h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight";

  let percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  let sp = Math.min(Math.max(parseInt(percent), 0), 100);

  let progress_percent =
    ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight - 800)) * 100;
  let progress_sp = Math.min(Math.max(parseInt(progress_percent), 0), 100);

  if (progress_el) progress_el.setAttribute("aria-valuenow", progress_percent);
  if (progress_el) progress_el.style.width = progress_sp + "%";

  if (cta) {
    let ctaPos = parseInt(cta.getAttribute("data-position")) || null;

    if (h[st] > lastScrollTop) {
      if (sp >= ctaPos) {
        cta.style.left = "0";
      }
    } else {
      if (sp <= ctaPos) {
        cta.style.left = "-300px";
      }
    }
  }

  // highlight toc links on scroll

  let ps_anchors = document.querySelectorAll(".article-copy h2.wp-block-heading");
  let tocLinks = document.querySelectorAll(".toc-wrapper ul li");

  ps_anchors.forEach((el, i) => {
 
    let bounding = el.getBoundingClientRect();
 
    if ( (bounding.y > 0) && (bounding.y < h.clientHeight) ) {
      console.log(el.textContent, bounding.y, h.clientHeight, i);

      tocLinks.forEach((li, j) => {
        tocLinks[j].querySelector('a').style.fontWeight = 'normal';
      });

      tocLinks[i].querySelector('a').style.fontWeight = 'bold';
    }

  });

  if (h[st] > lastScrollTop) {
    // downscroll
    if (sp >= 8) {
      // hide
      el.classList.add("visually-hidden");
      if (progress_el) progress.style.top = "-1px";
    }
  } else {
    // upscroll
    if (sp > 10) {
      // show
      if (el.classList.contains("visually-hidden"))
        el.classList.remove("visually-hidden");

      if (progress_el) progress.style.top = "99px";
    }
    if (sp <= 8) {
      // hide
      if (!el.classList.contains("visually-hidden"))
        el.classList.add("visually-hidden");

      if (progress_el) progress.style.top = "-1px";
    }
  }

  lastScrollTop = h[st] <= 0 ? 0 : h[st];
};

//if (progress_el)
if (el) document.addEventListener("scroll", throttle(updatePosition, 100));

/* Featured Slider instances */
let fSliderContainers = document.querySelectorAll(".featured-slider");

[].forEach.call(fSliderContainers, (fSlider, index, arr) => {
  let swiperFSlides = ".swiper-container";
  let swiperFSlidesDefaultOptions = {
    observer: true,
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    autoHeight: true,
  };

  let nSwiperFSlides = fSlider.querySelectorAll(swiperFSlides);
  let featuredSNav = fSlider.getElementsByTagName("ul");

  [].forEach.call(nSwiperFSlides, (slider, index, arr) => {
    let data = slider.getAttribute("data-swiper") || null;
    let dataOptions = {};

    //console.log('swiper instance', slider);
    if (data) {
      dataOptions = JSON.parse(data);
    }

    slider.options = Object.assign(
      {},
      swiperFSlidesDefaultOptions,
      dataOptions,
    );

    const swiper = new Swiper(slider, slider.options);

    let featuredXNavEL = featuredSNav[0].getElementsByTagName("li");
    [].forEach.call(featuredXNavEL, (item, index, arr) => {
      item.classList.remove("fs-nav-active");
      item.classList.add("fs-nav");

      if (index === 0) {
        item.classList.add("fs-nav-active");
      }
      item.addEventListener("click", function () {
        swiper.slideTo(index);
      });
    });

    swiper.on("realIndexChange", function () {
      [].forEach.call(featuredXNavEL, (item, index, arr) => {
        item.classList.remove("fs-nav-active");
        item.classList.add("fs-nav");

        if (index === swiper.realIndex) {
          item.classList.remove("fs-nav-active");
          item.classList.add("fs-nav-active");
        }
      });
    });
  });
});

/* General swiper instances */

let sliderSelector = ".swiper-container",
  defaultOptions = {
    observer: true,
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    autoHeight: true,
  };

let nSlider = document.querySelectorAll(sliderSelector);

[].forEach.call(nSlider, (slider, index, arr) => {
  let data = slider.getAttribute("data-swiper") || null;
  let dataOptions = {};

  if (data) {
    dataOptions = JSON.parse(data);
  }

  slider.options = Object.assign({}, defaultOptions, dataOptions);

  const swiper = new Swiper(slider, slider.options);

  /* stop on hover */

  if (
    typeof slider.options.autoplay !== "undefined" &&
    slider.options.autoplay !== false
  ) {
    slider.addEventListener("mouseenter", (e) => {
      swiper.autoplay.stop();
      //console.log("stop");
    });

    slider.addEventListener("mouseleave", (e) => {
      swiper.autoplay.start();
      //console.log("start");
    });
  }
});

/* End general swiper instances */

let nCounter = document.querySelectorAll(".countup");

[].forEach.call(nCounter, (counter, index, arr) => {
  let data = counter.getAttribute("data-countup") || null;
  let dataOptions = {};

  if (data) {
    dataOptions = JSON.parse(data);
  }

  counter.options = Object.assign({}, defaultOptions, dataOptions);

  let countTo = counter.options.countTo;

  var countUp = new CountUp(counter, countTo, counter.options);
  //countUp.start();
  countUp.handleScroll();
});

function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

function initializeClock(id, endtime) {
  const clock = document.getElementById(id);
  const daysSpan = clock.querySelector(".days");
  const hoursSpan = clock.querySelector(".hours");
  const minutesSpan = clock.querySelector(".minutes");
  const secondsSpan = clock.querySelector(".seconds");

  function updateClock() {
    const t = getTimeRemaining(endtime);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
    minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
    secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();
  const timeinterval = setInterval(updateClock, 1000);
}

const clockdiv = document.getElementById("countdown");

if (clockdiv) {
  const countdown = new Date(
    Date.parse(clockdiv.getAttribute("data-countdown")),
  );
  initializeClock("countdown", countdown);
}

const modalCovers = document.querySelectorAll(
  ".page-id-706 main .cover.modal a",
);
const modalReports = document.querySelector(".modal-reports");

[].forEach.call(modalCovers, (thing, index, arr) => {
  console.log("modalCovers", thing.href);

  thing.addEventListener("click", (e) => {
    e.preventDefault();
    console.log(e.target + " clicked");

    if (modalReports) {
      modalReports.querySelector("iframe").src = e.target;

      let reportsmodal = new bootstrap.Modal(modalReports, {
        keyboard: false,
      });

      let delay = function () {
        reportsmodal.show();
      };

      delay(delay, 50);
    }
  });
});

const wheelBlock = document.querySelectorAll(".wheel_block");

if (wheelBlock) {
  [].forEach.call(wheelBlock, (block, index, arr) => {
    const energyWheel = block.querySelector(".energy-wheel__wheel");
    const energyWheelSegments = energyWheel.querySelectorAll(
      ".energy-wheel__wheel__circle__segment",
    );
    const energyWheelBG = energyWheel.querySelectorAll(
      ".energy-wheel__wheel__circle__bg",
    );
    const energyWheelHover = energyWheel.querySelector(
      ".energy-wheel__wheel__circle__hover",
    );

    if (energyWheel) {
      let sliderContainer = block.querySelector(".swiper-wheel-container");

      let sliderDefaultOptions = {
        observer: true,
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        intialSlide: 0,
        autoHeight: true,
        noSwiping: true,
        createElements: false,
      };

      let data = sliderContainer.getAttribute("data-swiper") || null;
      let dataOptions = {};

      if (data) {
        dataOptions = JSON.parse(data);
      }

      sliderContainer.options = Object.assign(
        {},
        sliderDefaultOptions,
        dataOptions,
      );

      const swiper = new Swiper(sliderContainer, sliderContainer.options);

      let realIndex = swiper.realIndex;

      [].forEach.call(energyWheelBG, (segment, index, arr) => {
        segment.addEventListener("mouseenter", (e) => {
          segment.style.opacity = 0.2;
        });
        segment.addEventListener("mouseleave", (e) => {
          segment.style.opacity = 1;
        });
        segment.addEventListener("click", (e) => {
          swiper.slideTo(index + 2);
        });
      });

      swiper.on("realIndexChange", function () {
        realIndex = swiper.realIndex;

        [].forEach.call(energyWheelSegments, (segment, index, arr) => {
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(5)",
          ).style.opacity = 0;
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(4)",
          ).style.opacity = 0;
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(3)",
          ).style.opacity = 0;
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(2)",
          ).style.opacity = 0;
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(1)",
          ).style.opacity = 0;
          segment.querySelector("p").style.color = "#000";
        });

        if (realIndex > 0)
          energyWheelSegments[realIndex - 1].querySelector("p").style.color =
            "#fff";

        if (realIndex === 0) {
          energyWheelHover.style.opacity = 0;
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(5)",
          ).style.opacity = 1;
        } else {
          energyWheelHover.style.opacity = 1;
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(5)",
          ).style.opacity = 0;
        }

        if (realIndex === 1 || realIndex === 0) {
          energyWheelHover.style.transform = "rotate(45deg)";
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(5)",
          ).style.opacity = 1;
        }

        if (realIndex === 2) {
          energyWheelHover.style.transform = "rotate(135deg)";
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(3)",
          ).style.opacity = 1;
        }

        if (realIndex === 3) {
          energyWheelHover.style.transform = "rotate(225deg)";
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(2)",
          ).style.opacity = 1;
        }

        if (realIndex === 4) {
          energyWheelHover.style.transform = "rotate(315deg)";
          block.querySelector(
            ".energy-wheel__wheel__icon:nth-child(1)",
          ).style.opacity = 1;
        }
      });
    }
  });
}

const presentationCards = document.querySelectorAll(".document.presentation");
const reportCards = document.querySelectorAll(".document.report");
const documentCards = document.querySelectorAll(".document");
const videoModal = document.querySelector(".modal_document");
const documentFilters = document.querySelectorAll(".document-filters ul li a");
const documentContainer = document.querySelector(".documents-container");

const bodyClasses = document.body.classList;
const filterButtons = document.getElementById("filter-buttons");

if (filterButtons) {
  // active class on filter button if list class partiallly matches body class
  [].forEach.call(
    filterButtons.querySelectorAll("li"),
    async (filterButton, index, arr) => {
      if ([...bodyClasses].includes("category-" + filterButton.classList[1]))
        filterButton.classList.add("active");
    },
  );
}

let modal = null;

if (videoModal) {
  modal = new bootstrap.Modal(videoModal, {
    keyboard: false,
  });

  const closeBtn = videoModal.querySelector(".btn-close");
  closeBtn.addEventListener("click", async (e) => {
    modal.hide();
  });

  videoModal.addEventListener("hidden.bs.modal", (event) => {
    let div = videoModal.querySelector(".modal-body .doc");
    let header = videoModal.querySelector(".modal-header");
    let h4 = header.querySelector("h4");

    header.removeChild(h4);

    while (div.firstChild) {
      div.removeChild(div.firstChild);
    }
  });
}

if (documentCards.length > 0) {
  [].forEach.call(documentCards, async (card, index, arr) => {
    card.addEventListener("click", async (e) => {
      e.preventDefault();
      const postid = card.getAttribute("data-postid");

      const loading = document.createElement("img");
      loading.classList.add("loading");
      loading.src =
        "../../wp-content/themes/inspiredenergy/assets/images/loading.gif";

      videoModal.querySelector(".modal-body .doc").appendChild(loading);

      modal.show();

      const presentation = await fetch("/wp-json/wp/v2/document/" + postid)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          console.log("Error getting documents");
          console.error(error);
        });

      const report = await fetch("/wp-json/wp/v2/document/" + postid)
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.log("Error getting document");
          console.error(error);
        });

      const reportMediaID = report.acf.report_download;
      const reportIndexID = report.acf.report_index_download;

      //const reportMedia =  await fetch('/wp-json/wp/v2/media?parent=' + postid)
      const reportMedia = await fetch("/wp-json/wp/v2/media/" + reportMediaID)
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.log("Error getting media");
          console.error(error);
        });

      // const reportIndex = await fetch("/wp-json/wp/v2/media/" + reportIndexID)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     if (data) {
      //       return data;
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("Error getting media");
      //     console.error(error);
      //   });

       console.log('report', report, reportMediaID);

      if (report) {
        videoModal.querySelector(".modal-body .doc").removeChild(loading);

        const dlDiv = document.createElement("div");
        dlDiv.classList.add("dl", "d-flex");
        let reportButtonTitle = report.acf.report_download_text;
        let reportIndexButtonTitle = report.acf.report_index_download_button_text;
        
        if (reportMediaID > 0) {
          let reportButton = document.createElement("button");
          if (reportButtonTitle === "") {
            reportButtonTitle = "View report";
          }
          reportButton.innerHTML = reportButtonTitle;
          reportButton.classList.add("btn", "btn-primary", "me-2");
          dlDiv.appendChild(reportButton);
          
          reportButton.addEventListener("click", async (e) => {
            window.open(reportMedia.guid.rendered, "_blank");
          });
        }
        
        if (reportIndexID > 0) {
          let reportIndexButton = document.createElement("button");
          reportIndexButtonTitle = "View index";
          reportIndexButton.innerHTML = reportIndexButtonTitle;
          reportIndexButton.classList.add("btn", "btn-primary");
          
          reportIndexButton.addEventListener("click", async (e) => {
            window.open(reportIndex.guid.rendered, "_blank");
          });
          dlDiv.appendChild(reportIndexButton);
        }

        videoModal
          .querySelector(".modal-header")
          .insertAdjacentHTML(
            "afterBegin",
            '<h4 class="m-0">' + report.title.rendered + "</h4>",
          );
        videoModal
          .querySelector(".modal-body .doc")
          .insertAdjacentHTML("beforeEnd", report.content.rendered);

        videoModal.querySelector(".modal-body .doc").appendChild(dlDiv);

        
      } else if (presentation) {
        videoModal.querySelector(".modal-body .doc").removeChild(loading);

        videoModal
          .querySelector(".modal-header")
          .insertAdjacentHTML(
            "afterBegin",
            '<h4 class="m-0">' + presentation.title.rendered + "</h4>",
          );

        videoModal
          .querySelector(".modal-body .doc")
          .insertAdjacentHTML("beforeend", presentation.content.rendered);
      }
    });
  });
}

[].forEach.call(documentFilters, async (filter, index, arr) => {
  await filter.addEventListener("click", async (e) => {
    e.preventDefault();
    //console.log(filter, e.currentTarget.classList[0]);
    [].forEach.call(documentCards, (card, index, arr) => {
      card.parentNode.classList.add("d-none");
      //console.log(card.classList[card.classList.length - 1]);
    });

    [].forEach.call(documentCards, (card, index, arr) => {
      //if (filter.classList[0] === e.currentTarget.classList[0]) {
      if (
        e.currentTarget.classList[0] ===
        card.classList[card.classList.length - 1]
      ) {
        card.parentNode.classList.remove("d-none");
      } else {
        if (e.currentTarget.classList[0] === "all") {
          card.parentNode.classList.remove("d-none");
        }
      }
    });
  });
});

// TOC menu

function TOC({
  append = "#intro",
  containerClass = "toc-wrapper",
  linkClass = "toc-link",
  tags = "h1,h2",
} = {}) {
  let nav_el = document.createElement("nav");

  nav_el.classList.add(containerClass);

  let appendArea = document.querySelector(append);

  if (appendArea) {
    let links = document.querySelector(".article-copy").querySelectorAll(tags);
    let list_el = document.createElement("ul");
    list_el.classList.add("my-3");
    list_el.classList.add("pe-3");

    links.forEach((el, i) => {
      el.id = `h-${el.tagName.toLowerCase()}-${i}`;
      let list_item = document.createElement("li");
      let link = document.createElement("a");

      link.classList.add(linkClass);
      link.setAttribute("href", `#h-${el.tagName.toLowerCase()}-${i}`);
      link.classList.add(linkClass);

      link.classList.add(`${linkClass}-${el.tagName.toLowerCase()}`);
      link.textContent = el.textContent;
      list_item.appendChild(link);
      list_el.appendChild(list_item);
    });

    nav_el.appendChild(list_el);
    appendArea.appendChild(nav_el);
    document
      .querySelectorAll(".anchor-menu")
      .forEach((x) => x.classList.remove("d-none"));
  }
}

TOC({
  append: "#page-anchors-aside",
  containerClass: "toc-wrapper",
  linkClass: "toc-link",
  tags: "h2",
});

const listViewCheck = document.getElementById("flexCheckList");

if (listViewCheck) {
  listViewCheck.addEventListener("change", async (e) => {

    [].forEach.call(documentCards, (card, index, arr) => {
      const cardBody = card.querySelector('.card-body');
      const cardTitle = card.querySelector('.card-title');
      const cardExcerpt = card.querySelector('.card-excerpt');
      const titleWrap = card.querySelector('.title-wrap');

      if (e.target.checked) {
        documentContainer.classList.remove('card-view');
        documentContainer.classList.add('list-view');

        titleWrap.appendChild(cardTitle);
        titleWrap.appendChild(cardExcerpt);
      } else {
        documentContainer.classList.add('card-view');
        documentContainer.classList.remove('list-view');
        cardBody.insertBefore(cardTitle, titleWrap);
        cardBody.insertBefore(cardExcerpt, titleWrap);
      }
    });
  });
}

const gridViewCheck = document.getElementById("flexCheckGrid");

if (gridViewCheck) {
  gridViewCheck.addEventListener("change", async (e) => {

    [].forEach.call(documentCards, (card, index, arr) => {
      const cardBody = card.querySelector('.card-body');
      const cardTitle = card.querySelector('.card-title');
      const cardExcerpt = card.querySelector('.card-excerpt');
      const titleWrap = card.querySelector('.title-wrap');

      if (e.target.checked) {
        documentContainer.classList.add('card-view');
        documentContainer.classList.remove('list-view');
        cardBody.insertBefore(cardTitle, titleWrap);
        cardBody.insertBefore(cardExcerpt, titleWrap);
      } else {
        documentContainer.classList.remove('card-view');
        documentContainer.classList.add('list-view');

        titleWrap.appendChild(cardTitle);
        titleWrap.appendChild(cardExcerpt);
      }
    });
  });
}